import React from 'react'
import { navigate } from 'gatsby'
import { useParams } from '@reach/router'
import { Row, Col, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import EditOrder from '../../../components/order/edit'
import Helmet from 'react-helmet'
import 'bootstrap/scss/bootstrap.scss'

export default function AdminEditOrderPage(props) {
  const orderId = useParams()?.orderId
  return (
    <>
      <Helmet title="Илгээмж засах" defer={false} />
      <Row className="mb-3">
        <Col span={24}>
          <div style={{ position: 'absolute' }}>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate(`/admin/orders/${orderId}`)
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">Илгээмж засах</div>
        </Col>
      </Row>
      <EditOrder orderId={orderId} fromAdmin={true} />
    </>
  )
}
