import React, { useState, useEffect } from 'react'
import { Row, Col, Radio, Select, Button, Input, Spin, notification, Form } from 'antd'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import client from '../../api/client'
import PriceSelect from '../base/priceSelect'
import PartyChooser from './partyChooser'
import 'bootstrap/scss/bootstrap.scss'
import OrderTypeSelection from './orderTypeSelection'
import { isOwner } from '../../api/auth'

export default function EditOrder(props) {
  const [editOrderForm] = Form.useForm()
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [senderSelected, setSenderSelected] = useState(0)
  const [senderSelectedUser, setSenderSelectedUser] = useState(0)
  const [receiverSelected, setReceiverSelected] = useState(0)
  const [receiverSelectedUser, setReceiverSelectedUser] = useState(0)
  const [branchOptions, setBranchOptions] = useState([])
  const [selectedShipmentType, setSelectedShipmentType] = useState(undefined)
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [isPriceCustom, setIsPriceCustom] = useState(false)
  const [memberId, setMemberId] = useState(0)
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([])
  const [errorValidateParty, setErrorValidateParty] = useState(false)
  const [errorValidateOrderType, setErrorValidateOrderType] = useState(false)
  const [errorValidatePrice, setErrorValidatePrice] = useState(false)

  useEffect(() => {
    if (props.orderId) {
      setOrderId(props.orderId)
    }
  }, [props.orderId])
  useEffect(() => {
    setLoading(true)
    const init = async () => {
      const branchResponse = await client.get('/api/lookup/branches')
      const branchOptions = branchResponse.data
        .filter((x) => x.isReceiveOnline)
        .map((el) => {
          return { label: el.address, value: el.id }
        })
      setBranchOptions(branchOptions)
      if (orderId) {
        //#region Get Order OrderDetails
        const orderRes = await client.get(`/api/orders/${orderId}`)
        editOrderForm.setFieldsValue({
          shipmentType: orderRes.data.shipmentType,
          paymentType: orderRes.data.paymentType,
          branch: orderRes.data.branchId,
          customerComment: orderRes.data.customerComment,
        })
        setSenderSelected(orderRes.data.senderId)
        setSenderSelectedUser({
          name: orderRes.data.sender.firstName + ' ' + orderRes.data.sender.lastName,
          phone: orderRes.data.sender.phoneNumber,
          address: orderRes.data.sender.address,
        })
        setReceiverSelected(orderRes.data.receiverId)
        setReceiverSelectedUser({
          name: orderRes.data.receiver.firstName + ' ' + orderRes.data.receiver.lastName,
          phone: orderRes.data.receiver.phoneNumber,
          address: orderRes.data.receiver.fullAddress,
        })
        editOrderForm.setFieldsValue({ willReceiveMessage: orderRes.data.willReceiveMessage })
        setSelectedPrice(orderRes.data.price)
        setMemberId(orderRes.data.memberId)
        if (orderRes.data.price === 20 || orderRes.data.price === 50 || orderRes.data.price === 80 || orderRes.data.price === 100)
          setIsPriceCustom(false)
        else setIsPriceCustom(true)
        //#endregion
        const orderTypeRes = await client.get(`/api/orders/${orderId}/orderitems`)
        const orderTypes = orderTypeRes.data.map((x) => x.orderItemTypeId)
        setSelectedOrderTypes(orderTypes)
        setLoading(false)
      }
    }
    init()
  }, [orderId, editOrderForm])

  const updateOrder = async () => {
    setLoading(true)
    if (!senderSelected || !receiverSelected) setErrorValidateParty(true)
    if (selectedOrderTypes.length === 0) setErrorValidateOrderType(true)
    await editOrderForm.validateFields().then(async (values) => {
      const data = {
        price: selectedPrice,
        orderItemTypes: selectedOrderTypes,
        branchId: values.branch,
        senderId: senderSelected,
        receiverId: receiverSelected,
        sourceType: 'Web',
        shipmentType: values.shipmentType,
        paymentType: values.paymentType,
        customerComment: values.customerComment,
      }
      await client.put(`/api/orders/${orderId}`, data).then(async (res) => {
        notification.success({ message: 'Илгээмж амжилттай өөрчлөгдлөө' })
        navigate(-1)
      })
    })
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <Form form={editOrderForm} layout="vertical">
        <Row className="mb-1">
          <Col span={24}>
            <Form.Item
              name="shipmentType"
              label="Илгээмжийн төрлөө сонгоно уу"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
              rules={[
                {
                  required: true,
                  message: 'Илгээмжийн төрлөө оруулна уу!',
                },
              ]}
            >
              <Radio.Group className="sb-radio-buttons" value={selectedShipmentType} onChange={(e) => setSelectedShipmentType(e.target.value)}>
                <Radio value="Air">Агаарын ачаа</Radio>
                <Radio value="Ground">Газрын ачаа</Radio>
                {/* <Radio value="Express">Express ачаа</Radio> */}
                {isOwner() ? <Radio value="Container">Чингэлэг ачаа</Radio> : []}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col sm={24} lg={10}>
            <Form.Item
              name="branch"
              label="Хүлээн авах салбараа сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Хүлээн авах салбараа сонгоно уу!',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Сонгох"
                className="sb-select"
                popupClassName="sb-select-dropdown"
                options={branchOptions}
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" style={{ color: '#11427F', top: '49%' }} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <PartyChooser
              memberId={memberId}
              defaultSenderUser={senderSelectedUser}
              defaultReceiverUser={receiverSelectedUser}
              setSenderCallback={(e) => {
                setSenderSelected(e)
                if (receiverSelected) {
                  setErrorValidateParty(false)
                }
              }}
              setReceiverCallback={(e) => {
                setReceiverSelected(e)
                if (senderSelected) {
                  setErrorValidateParty(false)
                }
              }}
            />
            {errorValidateParty && <div style={{ color: '#ff4d4f' }}>Илгээгч болон хүлээн авагчаа сонгоно уу</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24} className="mb-2" style={{ fontSize: 18 }}>
            <span
              style={{
                lineHeight: 1,
                color: '#ff4d4f',
                fontSize: 14,
                marginLeft: 5,
                marginRight: 4,
                fontFamily: "'SimSun', sans-serif",
              }}
            >
              *
            </span>
            Явуулж буй илгээмжийн үнийн дүнгээ сонгоно уу:
          </Col>
          <Col span={24}>
            <PriceSelect
              isPriceCustom={isPriceCustom}
              defaultValue={selectedPrice}
              parentCallback={(price, isCustom) => {
                if (price > 0) {
                  setSelectedPrice(price)
                  setErrorValidatePrice(false)
                  setIsPriceCustom(isCustom)
                } else setErrorValidatePrice(true)
              }}
            />
            {errorValidatePrice && <div style={{ color: '#ff4d4f', fontSize: 16 }}>Илгээмжийн үнийн дүнг оруулна уу!</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <OrderTypeSelection
              isKiosk={false}
              defaultSelectedOrderTypes={selectedOrderTypes}
              refreshOrderItemTypeList={(selectedTypes) => {
                setSelectedOrderTypes(selectedTypes)
                if (selectedTypes.length > 0) setErrorValidateOrderType(false)
                else setErrorValidateOrderType(true)
              }}
            />
            {errorValidateOrderType && <div style={{ color: '#ff4d4f', fontSize: 16 }}>Илгээмжийн төрлөө сонгоно уу!</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <Form.Item
              name="paymentType"
              label="Төлбөрийн төрлөө сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Төлбөрийн төрлөө сонгоно уу!',
                },
              ]}
            >
              <Radio.Group className="sb-radio-buttons">
                <Radio value="Cash">Бэлэн</Radio>
                <Radio value="Transfer">Шилжүүлэг</Radio>
                <Radio value="Card">Карт</Radio>
                <Radio value="InMongolia">Монголд</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {!props.fromAdmin && (
          <Row className="mb-3">
            <Col span={24}>
              <Form.Item name="customerComment" label="Тайлбар">
                <Input.TextArea rows={4} className="sb-shadow sb-input-area" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row className="mb-5 mt-4">
          <Col span={16} offset={4}>
            <Button className="btn-sb btn-sb-primary w-100" style={{ fontSize: 18, height: 50, fontWeight: '500' }} onClick={() => updateOrder()}>
              Хадгалах
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}
