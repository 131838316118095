import React from "react";
import AdminLayout from "../components/adminlayout";
// import UserAuth from '../api/user';
// import { navigate } from "gatsby";
const PrivateAdminRoute = ({ component: Component, location, ...rest }) => {
  // const auth = UserAuth();
  // if (auth.hasUserSession()) {
  //    navigate("/admin/login")
  //    return null
  // }
  return (
    <AdminLayout>
      <Component {...rest} />
    </AdminLayout>
  );
};
export default PrivateAdminRoute;
