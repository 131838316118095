import { ExclamationCircleOutlined } from "@ant-design/icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Col, Modal, notification, Row, Table } from "antd";
import { navigate } from "gatsby";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { isOwner } from "../../../api/auth";
import client from "../../../api/client";
const BulkOrders = () => {
  const [loading, setLoading] = useState(true);
  const [bulkCount, setBulkCount] = useState(0);
  const [bulkData, setBulkData] = useState(0);
  const [paginatedBulkData, setPaginatedBulkData] = useState({
    search: "",
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: "createdDate",
      order: "descend",
    },
    includeContainerShipmentType: false
  });
  const bulkColumns = [
    {
      title: "Захиалагч",
      className: "text-center",
      render: (record) => {
        return record.member?.firstName + " " + record.member?.lastName;
      },
    },
    {
      title: "Тракын тоо",
      className: "text-center",
      dataIndex: "trackCount",
    },
    {
      title: "Илгээмжийн тоо",
      className: "text-center",
      dataIndex: "orderCount",
    },
    {
      title: "Тайлбар",
      className: "text-center",
      dataIndex: "instruction",
    },
    {
      title: "Хүлээн авсан огноо",
      className: "text-center",
      dataIndex: "createdDate",
      render: (record) => {
        return moment(record).format("MM/DD/YYYY");
      },
    },
    {
      title: "Үйлдэл",
      className: "text-center",
      width: "150px",
      render: (record) => {
        return (
          <>
            <Button
              className="btn-sb btn-sb-primary px-3 me-2"
              onClick={() => {
                navigate(`/admin/bulkorders/details/${record.id}`);
              }}
            >
              <FontAwesomeIcon icon={faEye} size="xs" />
            </Button>
            <Button
              className="btn-sb btn-sb-danger px-3"
              onClick={() => {
                if (!record.orderCount)
                  Modal.confirm({
                    title: "Та энэ багц илгээмжийг устгахдаа итгэлтэй байна уу?",
                    icon: <ExclamationCircleOutlined />,
                    className: "sb-modal",
                    okText: "Устгах",
                    okType: "danger",
                    cancelText: "Цуцлах",
                    centered: true,
                    onOk: () => {
                      client
                        .delete(`/api/orders/bulkorders/${record.id}`)
                        .then(async (res) => {
                          fetchBulkOrders();
                          notification.success({ message: "Багц илгээмж амжилттай устгагдлаа!" });
                        })
                        .catch((err) => console.error({ err }));
                    },
                  });
                else notification.error({ message: "Илгээмжтэй багцийг устгах боломжгүй!" });
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="1x" />
            </Button>
          </>
        );
      },
    },
  ];
  const fetchBulkOrders = useCallback(() => {
    client.post("/api/admin/bulkorders/paginated", { ...paginatedBulkData }).then(async (res) => {
      setBulkData(res.data.data);
      setBulkCount(res.data.total);
      setLoading(false);
    });
  }, [paginatedBulkData]);

  useEffect(() => {
    fetchBulkOrders();
  }, [paginatedBulkData, fetchBulkOrders]);

  return (
    <>
      <Helmet title="Багц илгээмжүүд" defer={false} />
      <Row className="sb-table-header" gutter={[8, 8]}>
        <Col flex="auto">
          <span className="sb-text-medium">Багц илгээмжүүд ({bulkCount})</span>
        </Col>
        {isOwner() ? 
          <Col flex="220px">
            <Checkbox className="sb-checkbox"
            onChange={(e)=>{
              setLoading(true)
              client
                .post(`/api/admin/bulkorders/paginated`, {paginatedBulkData, includeContainerShipmentType: e.target.checked ? true : false})
                .then(async (res) => {
                  setBulkCount(res.data.total);
                  setPaginatedBulkData({
                    ...paginatedBulkData,
                    includeContainerShipmentType: e.target.checked ? true : false,
                  })
                })
                .catch((error) => console.error(error))
            }}>
              Чингэлэгийн ачаа харах
            </Checkbox>
          </Col>
          : []}
      </Row>
      <Row>
        <Col span={24}>
          <Table
            className="sb-table"
            dataSource={bulkData}
            columns={bulkColumns}
            loading={loading}
            rowKey={(record) => record.id}
            locale={{ emptyText: "Мэдээлэл байхгүй байна" }}
            onChange={(pagination, filter, sorter) => {
              setLoading(true);
              setPaginatedBulkData({
                ...paginatedBulkData,
                pagination: {
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                },
              });
            }}
            pagination={{
              total: bulkCount,
              current: paginatedBulkData.pagination.current,
              pageSize: paginatedBulkData.pagination.pageSize,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default BulkOrders;
